import { Listing } from './listing';
import { TransactionStatus } from './transaction';
import { Batch, Package, Venue } from './venue';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export enum BookingStatus {
  PAID_ONLINE = 'PAID_ONLINE',
  PAID_OFFLINE = 'PAID_OFFLINE',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED'
}

export enum BookingHistoryType {
  UPCOMING = 'UPCOMING',
  PREVIOUS = 'PREVIOUS'
}

export enum AmountComponent {
  TICKET_PRICE = 'TICKET_PRICE',
  CONVENIENCE_FEES = 'CONVENIENCE_FEES'
}

// TODO: Validate if we need to store all the package details in this
export interface TicketDetail {
  packageId: string;
  count: number;
  meta?: Pick<Package, 'title' | 'description' | 'price' | 'discount'>;
}

export interface Booking {
  id?: string;
  userId: string;
  propId: string;
  listingId: string;
  venueId: string;
  tickets: Array<TicketDetail>;
  /**
   * Choice of selected session batches
   */
  sessionBatches: Array<Batch>;
  bookingStatus?: BookingStatus;
  totalAmount: number;
  amountBreakdown: Partial<Record<AmountComponent, number>>;
  transactionStatus?: TransactionStatus;
  createdAt?: Date;
  transcationId?: string;
  meta?: { listing: Pick<Listing, 'title'>; venue: Pick<Venue, 'location' | 'address' | 'phoneNumber'> };
}
