// components/Button.tsx
import React from 'react';

import LoadingSpin from '../loading/LoadingSpin';
interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label: string;
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, className, isLoading, ...rest }) => (
  <button
    onClick={onClick}
    className={`flex items-center justify-center gap-4 px-4 py-2 rounded-md ${
      isLoading ? 'cursor-not-allowed opacity-70' : ''
    } ${className}`}
    disabled={isLoading}
    {...rest}
  >
    {isLoading && <LoadingSpin />}
    {label}
  </button>
);

export default Button;
