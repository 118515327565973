import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import InputFile, { uploadLocalFiles } from '../../components/input/InputFile';
import InputSelect from '../../components/input/InputSelect';
import InputTextArea from '../../components/input/InputTextArea';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getBusinessDetailThunk, saveBusinessDetailThunk } from '../../redux/reducer/propSlice';
import { PresignedPostUploadType } from '../../utils/models/common';
import { cityOptions, FileType, withErrorHandler } from '../../utils/utils';
import { BusinessDetailFormData, getBusinessDetailSchema } from '../../validation/general/businessDetail';

const BusinessDetails: React.FC = () => {
  const { businessDetail, isLoading } = useAppSelector(state => state.prop);
  let values: BusinessDetailFormData | undefined = undefined;

  const domainSuffix = `.${window.location.host.replace('www', '')}`;
  if (businessDetail) {
    values = {
      email: businessDetail.email,
      businessName: businessDetail.businessName!,
      phoneNumber: businessDetail.phoneNumber!,
      alternatePhoneNumber: businessDetail.alternatePhoneNumber!,
      city: businessDetail.city ?? '',
      address: businessDetail.address!,
      generatedDomain: businessDetail.generatedDomain?.replace(domainSuffix, '') ?? '',
      gst: (businessDetail.documents?.GST!.links[0]
        ? { loc: businessDetail.documents?.GST!.links[0] }
        : undefined) as FileType
    };
  }

  const businessDetailSchema = getBusinessDetailSchema(!values?.generatedDomain);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch
  } = useForm({
    resolver: yupResolver(businessDetailSchema),
    values
  });
  const commonProps = {
    register,
    errors,
    topLabel: true
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getBusinessDetailThunk());
  }, [dispatch]);

  const onSave = async (data: BusinessDetailFormData) => {
    withErrorHandler(async () => {
      const location = (await uploadLocalFiles(PresignedPostUploadType.PROP_DOCUMENT_GST, data.gst))[0];
      setValue('gst', { loc: location });
      const request = { ...data, gst: location };
      await dispatch(saveBusinessDetailThunk(request)).unwrap();
      // dispatch(setGeneratedDomain(data.generatedDomain));

      if (!businessDetail?.businessName) {
        dispatch(getBusinessDetailThunk());
      }
    }, 'Successfully saved Business Details');
  };

  return (
    <div className=" w-full  p-4">
      <h1 className="text-2xl w-full font-semibold mb-4">Business Details</h1>
      <form className="w-full" onSubmit={handleSubmit(onSave)}>
        <div className="w-full flex gap-4">
          <div className="grid grid-cols-1 gap-1 w-5/12">
            <Input
              {...commonProps}
              type="text"
              label="Business Name"
              name="businessName"
              placeholder="Enter your registered Business name"
              required
            />
            <Input
              {...commonProps}
              type="email"
              label="Business Email"
              name="email"
              placeholder="Enter your business email"
              required
            />
            <Input
              {...commonProps}
              type="text"
              label="Sub Domain"
              name="generatedDomain"
              placeholder="Enter Domain"
              suffix={domainSuffix}
              disabled={!!businessDetail?.generatedDomain}
              required
            />
            <Input
              {...commonProps}
              type="tel"
              label="Business Phone Number"
              name="phoneNumber"
              placeholder="Enter Your Business Phone number"
              required
            />
            <Input
              {...commonProps}
              type="tel"
              label="Alternate Phone Number"
              name="alternatePhoneNumber"
              placeholder="Enter Your Business Phone number"
              required
            />
            <InputSelect
              {...commonProps}
              label="Select City"
              placeholder="Select City from list"
              name="city"
              options={cityOptions}
              required
            />
          </div>
          <div className="w-5/12">
            <InputTextArea
              {...commonProps}
              label="Registered Business Address"
              name="address"
              placeholder="Enter your registered business address"
              required
            />
            <br className="m-1" />
            <InputFile
              name="gst"
              label="Upload GST"
              required
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
              addtionalText={
                <div>
                  <p className="text-lg mt-1 text-gray-300 ">Click here to add file</p>
                  <p className="text-lg  mt-1 text-gray-300 ">(Only PDF)</p>
                </div>
              }
              accept=".pdf"
            />
          </div>
        </div>
        <Button
          type="submit"
          className="w-1/5 mt-6 p-2 bg-[#35e0a1] text-black rounded-md font-semibold hover:bg-teal-600 transition duration-300"
          label="Save Changes"
          isLoading={isLoading[saveBusinessDetailThunk.pending.toString()]}
        />
      </form>
    </div>
  );
};

export default BusinessDetails;
