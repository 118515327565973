import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Categories, { CategoriesProps } from './Categories';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import InputFile, { uploadLocalFiles } from '../../components/input/InputFile';
import InputTextArea from '../../components/input/InputTextArea';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import {
  getBusinessDetailThunk,
  getWebsiteDetailThunk,
  saveWebsiteDetailThunk
} from '../../redux/reducer/propSlice';
import { PresignedPostUploadType } from '../../utils/models/common';
import { withErrorHandler } from '../../utils/utils';
import { WebsiteDetailFormData, websiteDetailSchemma } from '../../validation/general/websiteDetails';

// function removeId<T>(obj: T, visited = new WeakSet<object>()): void {
//   // If the object is null or undefined, stop recursion
//   if (obj === null) {
//     return;
//   }

//   // Ensure obj is an object before adding it to the WeakSet
//   if (typeof obj !== 'object') {
//     return;
//   }

//   // If we've already visited this object, stop to prevent infinite loops
//   if (visited.has(obj)) {
//     return;
//   }

//   // Mark this object as visited
//   visited.add(obj);

//   // If the object is an array, iterate over each element
//   if (Array.isArray(obj)) {
//     obj.forEach(item => removeId(item, visited));
//   }
//   // If the object is a non-null object (not an array), check each property
//   else if (typeof obj === 'object') {
//     Object.keys(obj).forEach(key => {
//       // Check if the key is '_id'
//       if (key === '_id') {
//         // Check if the property is configurable before attempting to delete
//         const descriptor = Object.getOwnPropertyDescriptor(obj, key);
//         if (descriptor && descriptor.configurable) {
//           // Delete the '_id' property from the object
//           delete (obj as Record<string, unknown>)[key];
//         }
//       } else {
//         // Recurse into nested properties
//         removeId((obj as Record<string, unknown>)[key], visited);
//       }
//     });
//   }
// }

const WebsiteDetails: React.FC = () => {
  const { businessDetail, websiteDetail, isLoading } = useAppSelector(state => state.prop);
  const values: WebsiteDetailFormData = { ...websiteDetail } as unknown as WebsiteDetailFormData;
  // removeId(values);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    watch,
    trigger
  } = useForm<WebsiteDetailFormData>({
    resolver: yupResolver(websiteDetailSchemma),
    defaultValues: { faqs: [{}] },
    values
  });
  const dispatch = useAppDispatch();
  const faqs = watch('faqs');
  const primaryColor = watch('primaryColor');
  const secondaryColor = watch('secondaryColor');

  // Auto Appending # in hex codes if it does not exists
  useEffect(() => {
    if (primaryColor && !primaryColor.startsWith('#')) {
      setValue('primaryColor', '#' + primaryColor);
    }
  }, [primaryColor]);
  useEffect(() => {
    if (secondaryColor && !secondaryColor.startsWith('#')) {
      setValue('secondaryColor', '#' + secondaryColor);
    }
  }, [secondaryColor]);

  useEffect(() => {
    dispatch(getWebsiteDetailThunk());
    if (!businessDetail) {
      dispatch(getBusinessDetailThunk());
    }
  }, [dispatch]);

  const onSubmit = async (data: WebsiteDetailFormData) => {
    withErrorHandler(async () => {
      const logo = (await uploadLocalFiles(PresignedPostUploadType.PROP_LOGO, data.logo))[0];
      const landingImages = await uploadLocalFiles(
        PresignedPostUploadType.PROP_LANDING_IMAGES,
        ...data.landingImages
      );
      setValue(
        'landingImages',
        landingImages.map(loc => ({ loc }))
      );
      const categoriesImageLoc = await uploadLocalFiles(
        PresignedPostUploadType.PROP_CATEGORY_IMAGES,
        ...data.categories.map(category => category.image)
      );
      setValue(
        'categories',
        data.categories.map((obj, index) => ({ ...obj, image: { loc: categoriesImageLoc[index] } }))
      );
      await dispatch(
        saveWebsiteDetailThunk({
          ...data,
          logo,
          landingImages,
          faqs: faqs.map(faq => ({ question: faq.question, answer: faq.answer })),
          categories: data.categories.map((obj, index) => ({ ...obj, image: categoriesImageLoc[index] }))
        })
      ).unwrap();
    }, 'Successfully saved Website Details');
  };

  const commonProps = {
    topLabel: true,
    required: true,
    register,
    errors
  };
  const faqsError = _.get(errors, 'faqs')?.message as string;
  return (
    <main className="p-8 w-fulll min-h-screen">
      <form onSubmit={handleSubmit(onSubmit)} className="flex items-start">
        <div className="w-[85%] pr-20">
          <section className="mb-8">
            <h3 className="text-2xl font-semibold mb-4">Website Theme</h3>
            {(websiteDetail || isSubmitSuccessful) && businessDetail?.generatedDomain && (
              <div className="mb-4 text-lg">
                Your website would be viewable at{' '}
                <a
                  href={`https://${businessDetail.generatedDomain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-blue-500"
                >
                  {businessDetail.generatedDomain}
                  <span className="fas fa-external-link-alt"></span>
                </a>
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Primary Color"
                  name="primaryColor"
                  type="text"
                  placeholder="Enter Primary color hex code"
                  {...commonProps}
                />
                <Input
                  label="Secondary Color"
                  name="secondaryColor"
                  type="text"
                  placeholder="Enter Secondary color hex code"
                  {...commonProps}
                />
                <Input
                  label="Website Domain"
                  name="domain"
                  type="text"
                  placeholder="Enter your website domain"
                  {...commonProps}
                  required={false}
                />
              </div>
              <InputFile
                name="logo"
                label="Upload Logo"
                required
                watch={watch}
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                addtionalText={
                  <p className="text-sm font-medium mt-1 text-gray-300">Click here to add logo</p>
                }
                accept="image/*"
              />
            </div>
          </section>

          {/* Website Details Section */}
          <section className="mb-8">
            <h3 className="text-2xl font-semibold mb-4">Website Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Input label="Title" name="title" type="text" placeholder="Enter Title" {...commonProps} />
                <InputTextArea
                  label="Description"
                  name="description"
                  maxLength={500}
                  placeholder="Enter Description"
                  {...commonProps}
                />
                <Categories
                  {...({ register, errors, setValue, watch, trigger } as unknown as CategoriesProps)}
                />
                <Input
                  label="Categories Title"
                  name="categoriesTitle"
                  type="text"
                  placeholder="Enter Categories Title"
                  {...commonProps}
                />
              </div>
              <InputFile
                name="landingImages"
                label="Landing Images"
                required
                multiple
                maxFiles={5}
                watch={watch}
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                addtionalText={
                  <p className="text-sm font-medium mt-1 text-gray-300">Click here to add images</p>
                }
                accept="image/*"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Statistics Title"
                name="aggregatedStatTitle"
                type="text"
                placeholder="Enter Aggregated Statistics Title"
                {...commonProps}
              />
              <Input
                label="Statistics Description"
                name="aggregatedStat"
                type="text"
                placeholder="Enter Aggregated Statistics"
                {...commonProps}
              />
              <Input
                label="Rating Title"
                name="ratingTitle"
                type="text"
                placeholder="Enter Rating Title"
                {...commonProps}
              />
              <Input
                label="Rating Description"
                name="rating"
                type="text"
                placeholder="Enter Rating"
                {...commonProps}
              />
            </div>
          </section>

          {/* FAQs Section */}
          <section className="mb-8">
            <div>
              <div className="flex justify-between mb-4">
                <h3 className="text-2xl font-semibold">FAQ&apos;s</h3>
                <button
                  onClick={async () => {
                    const isValid = await trigger('faqs');
                    if (isValid) {
                      setValue('faqs', [...faqs, { question: '', answer: '' }]);
                    }
                  }}
                  type="button"
                  className="border-2 border-black bg-gray-200 rounded-md px-5 py-2"
                >
                  Add FAQ
                </button>
              </div>
              {faqsError && <p className="text-red-600 text-sm font-semibold">{faqsError}</p>}
            </div>
            <div className="grid grid-cols-2 gap-4">
              {faqs.map((faq, index) => (
                <div key={index} className="flex flex-col space-y-2">
                  <Input
                    label={`Question ${index + 1}`}
                    name={`faqs.${index}.question`}
                    type="text"
                    placeholder={`Enter Question ${index + 1}`}
                    {...commonProps}
                  />
                  <InputTextArea
                    label={`Answer ${index + 1}`}
                    name={`faqs.${index}.answer`}
                    maxLength={500}
                    placeholder={`Enter Answer ${index + 1}`}
                    {...commonProps}
                  />
                </div>
              ))}
            </div>
          </section>

          {/* Policies Section */}
          <section className="mb-8">
            <h3 className="text-2xl font-semibold mb-4">Policies</h3>
            <div className="grid grid-cols-2 gap-4">
              <InputTextArea
                label="Terms & Conditions"
                name="termsAndCondition"
                maxLength={500}
                placeholder="Enter Terms & Conditions"
                {...commonProps}
              />
              <InputTextArea
                label="Cancellation Policies"
                name="cancellationPolicy"
                maxLength={500}
                placeholder="Enter Cancellation Policies"
                {...commonProps}
              />
              <InputTextArea
                label="Privacy Policies"
                name="privacyPolicy"
                maxLength={500}
                placeholder="Enter Privacy Policies"
                {...commonProps}
              />
            </div>
          </section>
        </div>
        <Button
          type="submit"
          className="mt-12 sticky top-14 p-2 px-6 bg-[#35e0a1] text-black rounded-md font-semibold hover:bg-teal-600 transition duration-300"
          label=" Save Changes"
          isLoading={isLoading[saveWebsiteDetailThunk.pending.toString()]}
        />
      </form>
    </main>
  );
};

export default WebsiteDetails;
