import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import Input from '../../../components/input/Input';
import InputTextArea from '../../../components/input/InputTextArea';
import { useAppDispatch } from '../../../hooks/store';
import { errorMessage, successMessage } from '../../../hooks/useNotifications';
import { submitQueryThunk } from '../../../redux/reducer/propSlice';
import { ContactUsFormData, contactUsSchema } from '../../../validation/contactUs';

const ContactForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ContactUsFormData>({
    resolver: yupResolver(contactUsSchema)
  });
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<ContactUsFormData> = async data => {
    try {
      console.log(data);
      await dispatch(submitQueryThunk(data)).unwrap();
      successMessage('Message sent successfully! One of our representative would reach out to yo shortly.');
      reset(); // Clear the form after successful submission
    } catch (error) {
      console.error('Error sending message:', error);
      errorMessage('Failed to send message. Please try again.');
    }
  };
  const commonProps = {
    register,
    errors,
    required: true,
    className:
      'py-1 border-gray-300 bg-white focus-within:ring-purple-500 focus-within:border-purple-500 focus-within:ring-2'
  };

  return (
    <section
      id="contact-us"
      className="flex flex-col md:flex-row justify-center mt-10 p-4 items-center bg-purple-500"
    >
      {/* Left Section */}
      <div className="text-center md:text-left md:w-1/2 px-8 pt-10">
        <h1 className="text-white text-3xl font-bold mb-4">Get in touch with us</h1>
        <p className="text-white text-lg">Open your digital store & grow your business today</p>
      </div>

      {/* Right Section */}
      <div className="bg-white shadow-lg rounded-lg p-8 md:w-1/3 w-full my-12">
        <h2 className="text-gray-800 text-xl font-semibold mb-4">Contact us</h2>
        <p className="text-gray-600 text-sm mb-6">
          Complete the information below and we will get back to you soon
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <Input type="text" name="name" placeholder="Name" {...commonProps} />
          <Input type="text" name="email" placeholder="Email" {...commonProps} />
          <Input type="tel" name="phoneNumber" placeholder="Phone Number" {...commonProps} />
          <InputTextArea name="message" placeholder="Write your message" {...commonProps} />

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-3 bg-black text-white rounded-md hover:bg-gray-800 transition"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
