import axios from 'axios';

import { toError } from './utils';

const BASE_URL = '/api/v1';

const axiosTokenInstance = axios.create({
  baseURL: BASE_URL
});

const axiosInstance = axios.create({
  baseURL: BASE_URL
});

axiosInstance.interceptors.request.use(
  async config => {
    config.withCredentials = true;
    return config;
  }
  // err => Promise.reject(err)
);
axiosTokenInstance.interceptors.request.use(
  async config => {
    config.withCredentials = true;
    return config;
  }
  // err => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  response => response,
  async err => {
    const prvsRequest = err?.config;
    if (err?.response?.status === 401 && !prvsRequest?.sent) {
      prvsRequest.sent = true;
      try {
        if (prvsRequest.url?.includes('/prop')) {
          await axiosTokenInstance.get('/prop/refresh-token/verify');
          // .then()
          // .catch(error => {
          //   throw toError(error);
          // });
          return axiosInstance(prvsRequest);
        } else {
          throw toError(err, 'Unauthorized');
        }
      } catch (error) {
        throw toError(error, 'Unauthorized');
      }
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
