import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import PackageModal from './PackageModal';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import InputDate from '../../components/input/InputDate';
import InputSelect from '../../components/input/InputSelect';
import InputTextArea from '../../components/input/InputTextArea';
import Loading from '../../components/loading/Loading';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { errorMessage } from '../../hooks/useNotifications';
import {
  addVenueThunk,
  deletePackageThunk,
  getListingThunk,
  getVenueThunk,
  updateVenueThunk
} from '../../redux/reducer/listingSlice';
import { Package } from '../../utils/entity/venue';
import { cityOptions, withErrorHandler } from '../../utils/utils';
import {
  AddVenueFormData,
  addVenueSchema
} from '../../validation/operations/addOrEditProduct/addVenueSchema';

const AddVenue: React.FC = () => {
  const [displayPackageModal, setDisplayPackageModal] = useState<boolean>(false);
  const [packageModalIndex, setPackageModalIndex] = useState<number>(0);
  const [packagesPreviousState, setPackagePreviousState] = useState<Package[]>([]);
  const navigate = useNavigate();
  const { listingId, venueId } = useParams();

  const listing = useAppSelector(state => state.listing.listingMap[listingId ?? '']);
  const venue = useAppSelector(state => state.listing.venueMap[venueId ?? '']);
  const { isLoading, error } = useAppSelector(state => state.listing);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listing) {
      dispatch(getListingThunk(listingId!));
    }
    if (venueId && !venue) {
      dispatch(getVenueThunk({ listingId: listingId!, venueId }));
    }
  }, [venueId]);

  let values: AddVenueFormData | undefined;
  if (venue) {
    values = {
      ...venue,
      startTime: venue.slots[0][0][0].from,
      endTime: venue.slots[0][0][0].to,
      date: venue.slots[0][0][0].from
    };
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger
  } = useForm({
    resolver: yupResolver(addVenueSchema),
    values
  });

  if (isLoading[getListingThunk.pending.toString()] || isLoading[getVenueThunk.pending.toString()]) {
    return <Loading />;
  } else if (error[getListingThunk.pending.toString()] || error[getVenueThunk.pending.toString()]) {
    navigate('/not-found');
    errorMessage(error[getListingThunk.pending.toString()] ?? error[getVenueThunk.pending.toString()]);
    return <div />;
  }

  const onSave = (veneuData: AddVenueFormData) => {
    withErrorHandler(async () => {
      if (venueId) {
        await dispatch(updateVenueThunk({ listingId: listingId!, venueId, veneuData })).unwrap();
      } else {
        const { id } = await dispatch(addVenueThunk({ listingId: listingId!, veneuData })).unwrap();
        navigate(`${id}`);
      }
    }, 'Successfully Added Venue');
  };

  const commonProps = {
    register,
    errors,
    required: true,
    topLabel: true
  };

  const packages = watch('packages') ?? [];
  const handlePackageModelOpen = (index: number) => {
    setPackagePreviousState(_.cloneDeep(packages));
    setPackageModalIndex(index);
    setDisplayPackageModal(true);
  };

  const packagesError = _.get(errors, 'packages')?.message as string;
  return (
    <main className="w-10/12 font-medium p-8">
      <h1 className="text-xl font-bold mb-4">Venue</h1>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="grid grid-cols-2 gap-6 mb-4">
          <div className="space-y-2">
            <div className="flex flex-wrap gap-4 [&>*]:flex-1">
              <InputDate
                name="date"
                label="Date"
                placeholder="Select Date"
                setValue={setValue}
                watch={watch}
                errors={errors}
                required
              />
              <InputDate
                name="startTime"
                label="Start Time"
                setValue={setValue}
                watch={watch}
                errors={errors}
                dateFormat="h:mm aa"
                defaultDate={watch('date')}
                isTimeSelect
                required
              />
              <InputDate
                name="endTime"
                label="End Time"
                setValue={setValue}
                watch={watch}
                errors={errors}
                defaultDate={watch('date')}
                dateFormat="h:mm aa"
                isTimeSelect
                required
              />
            </div>
            <Input
              label="Workshop Phone Number"
              prefix="+91"
              type="tel"
              name="phoneNumber"
              {...commonProps}
            />
            <Input label="Venue Title" type="text" name="title" {...commonProps} />
          </div>
          <InputTextArea
            label="Venue Address"
            name="address"
            placeholder="Enter your venue address"
            {...commonProps}
          />
        </div>
        <div className="grid grid-cols-2 gap-6 mb-4">
          <InputSelect
            label="City"
            placeholder="Select City"
            name="city"
            options={cityOptions}
            {...commonProps}
          />
          <Input
            label="Venue Location"
            type="text"
            name="location"
            placeholder="Paste your venue's google map link"
            {...commonProps}
          />
        </div>

        <div className="my-8 space-y-4">
          <h2 className="block font-bold text-xl">Packages</h2>
          <div className="bg-white rounded grid grid-cols-3 gap-4">
            <button
              onClick={() => handlePackageModelOpen(packages.length)}
              className="bg-[#f6f6f6] p-4 text-center rounded cursor-pointer flex flex-col items-center justify-center"
              type="button"
            >
              <i className="fas fa-plus text-[#5a5a5a]"></i>
              <p className="font-medium text-[#9b9b9b]">Click here to add Package</p>
            </button>
            {packages.map((packageDetail, index) => (
              <div key={index} className=" bg-[#f6f6f6] p-4 rounded relative">
                <p className="text-[15px] font-[600]">{packageDetail.title}</p>
                <p className="text-[15px] font-[450] mt-2 whitespace-pre-line">{packageDetail.description}</p>
                <p className="text-[16px]  font-[600] mt-2">₹ {packageDetail.price}</p>
                <p className="text-[14px] font-[600] mt-2 flex justify-between">
                  Ticket: <span>{packageDetail.totalTickets}</span>
                </p>
                <button
                  className="text-black text-[13px] underline mt-3"
                  type="button"
                  onClick={() => handlePackageModelOpen(index)}
                >
                  Click here to edit
                </button>
                <button
                  className="absolute top-0 right-0 text-black mt-1 mr-1 rounded-full w-4 h-4 flex items-center justify-center"
                  type="button"
                  onClick={() => {
                    withErrorHandler(async () => {
                      if (packageDetail.id && listingId && venueId) {
                        await dispatch(
                          deletePackageThunk({ listingId, venueId, packageId: packageDetail.id })
                        ).unwrap();
                      }
                      setValue(
                        'packages',
                        packages.filter((_, i) => i !== index)
                      );
                    }, `Successfully removed ${packageDetail.title}`);
                  }}
                >
                  <i className="fas fa-times text-xs"></i>
                </button>
              </div>
            ))}
          </div>
          {packagesError && <p className="text-red-600 text-sm font-semibold mt-1">{packagesError}</p>}
        </div>
        <PackageModal
          isOpen={displayPackageModal}
          resetState={() => setValue('packages', packagesPreviousState)}
          onClose={() => setDisplayPackageModal(false)}
          index={packageModalIndex}
          listingId={listingId!}
          venueId={venueId}
          {...{ register, errors, trigger, setValue, watch }}
        />
        <div>
          <Button
            className="bg-[#35e0a1] text-black px-8 py-2 mt-6 rounded"
            type="submit"
            label="Save Changes"
            isLoading={isLoading[addVenueThunk.pending.toString()]}
          />
        </div>
      </form>
    </main>
  );
};

export default AddVenue;
