import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getBankDetailThunk, saveBankDetailThunk } from '../../redux/reducer/propSlice';
import { withErrorHandler } from '../../utils/utils';
import { bankDetailsSchema, BankDetailsFormData } from '../../validation/general/bankDetail';

const BankDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { bankDetail, isLoading } = useAppSelector(state => state.prop);
  useEffect(() => {
    if (!bankDetail) {
      dispatch(getBankDetailThunk());
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<BankDetailsFormData>({
    resolver: yupResolver(bankDetailsSchema),
    values: bankDetail
  });

  const onSave = async (data: BankDetailsFormData) => {
    withErrorHandler(async () => {
      await dispatch(saveBankDetailThunk(data)).unwrap();
    }, 'Failed to update bank details');
  };

  return (
    <div className="w-10/12 font-medium p-8">
      <h1 className="text-xl font-bold mb-4">Bank Detail</h1>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="w-5/12 mb-4 space-y-4">
          <Input
            topLabel
            label="Account Number"
            type="text"
            name="accountNumber"
            placeholder="Enter Account Number"
            register={register}
            errors={errors}
            required
          />

          <Input
            topLabel
            label="Account Name"
            type="text"
            name="accountName"
            placeholder="Enter Account Name"
            register={register}
            errors={errors}
            required
          />

          <Input
            topLabel
            label="IFSC Code"
            type="text"
            name="ifscCode"
            placeholder="Enter IFSC Code"
            register={register}
            errors={errors}
            required
          />

          <Input
            topLabel
            label="Bank Address"
            type="text"
            name="bankAddress"
            placeholder="Enter Bank Address"
            register={register}
            errors={errors}
            required
          />
        </div>
        <Button
          className="bg-[#35e0a1] text-black px-8 py-2 mt-6 rounded"
          type="submit"
          label="Save"
          isLoading={isLoading[saveBankDetailThunk.pending.toString()]}
        />
      </form>
    </div>
  );
};

export default BankDetail;
