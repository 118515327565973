import * as yup from 'yup';

export const bankDetailsSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .required('Account number is required')
    .matches(/^\d{9,18}$/, 'Account number must be between 9 to 18 digits'),
  ifscCode: yup.string().required('IFSC code is required'),
  accountName: yup.string().required('Account name is required'),
  bankAddress: yup.string().required('Bank address is required')
});

export type BankDetailsFormData = yup.InferType<typeof bankDetailsSchema>;
