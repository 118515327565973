import { useState } from 'react';

import { Button } from './sub/Button';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="sticky top-0 z-50 shadow-lg bg-[#F0FDF8BF] shadow-[#0000000D] backdrop-blur-md">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        {/* Brand Logo */}
        <a href="/#home">
          <h1 className="bri text-3xl md:text-5xl font-bold">Zittle</h1>
        </a>

        {/* Hamburger Menu (Visible on Small Screens) */}
        <button
          className="md:hidden text-2xl text-[#202322] focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? '✖' : '☰'}
        </button>

        {/* Navigation Links */}
        <nav
          className={`int text-base font-semibold text-[#202322] space-x-6 md:space-x-14 md:flex ${
            isOpen ? 'block' : 'hidden'
          } absolute md:static top-16 left-0 w-full md:w-auto bg-[#F0FDF8BF] md:bg-transparent backdrop-blur-md md:backdrop-blur-none p-4 md:p-0 shadow-md md:shadow-none`}
        >
          <a href="#home" className="block md:inline hover:text-blue-500 py-2">
            Home
          </a>
          <a href="#services" className="block md:inline hover:text-blue-500 py-2">
            Services
          </a>
          <a href="#benefits" className="block md:inline hover:text-blue-500 py-2">
            Benefits
          </a>
          <a href="#about" className="block md:inline hover:text-blue-500 py-2">
            About Us
          </a>
          <a href="#faqs" className="block md:inline hover:text-blue-500 py-2">
            FAQ
          </a>
          <a href="#contact-us" className="block md:hidden hover:text-blue-500 py-2">
            Contact Us
          </a>
          {/* <a href="/login" className="block md:hidden hover:text-blue-500 py-2">
            Get Started
          </a> */}
        </nav>

        {/* Button (Hidden on Small Screens) */}
        <div className="hidden md:block space-x-2">
          <a
            href="/login"
            className="int border-2 border-gray-500 text-[#202322] font-semibold px-6 py-3 rounded-xl hover:bg-green-200"
          >
            Get Started
          </a>
          <Button />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
