import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import InputSelect from '../../components/input/InputSelect';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getProductsThunk } from '../../redux/reducer/listingSlice';
import { getPrettyDateString } from '../../utils/utils';
import {
  OfflineTicketFormData,
  offlineTicketSchema
} from '../../validation/operations/ticket/offilineTicket';

const OfflineTickets: React.FC = () => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<OfflineTicketFormData>({
    resolver: yupResolver(offlineTicketSchema)
  });

  const onSaveDetails = async (data: OfflineTicketFormData) => {
    console.log(data);
    // await dispatch(updateVenuePackagesThunk({ venueId: product.venueId, packages: data.packages }));
  };
  const dispatch = useAppDispatch();
  const { products, isLoading } = useAppSelector(state => state.listing);
  const selectedListingId = watch('listingId');
  const selecteVenueId = watch('venueId');
  const selectedVenue = products
    .find(product => product.id === selectedListingId)
    ?.venues?.find(venue => venue.id === selecteVenueId);

  useEffect(() => {
    dispatch(getProductsThunk());
  }, []);

  const packagesCnt = watch('packages') ?? [];

  const packageCntString = JSON.stringify(packagesCnt);
  useEffect(() => {
    if (packagesCnt) {
      let bookingAmount = 0,
        discount = 0;

      selectedVenue?.packages.forEach((pkg, ind) => {
        const price = pkg.price * (packagesCnt[ind] ?? 0);
        bookingAmount += price;
        discount += (price * pkg.discount) / 100;
      });

      setValue('bookingAmount', bookingAmount);
      setValue('discount', discount);
      setValue('totalAmount', bookingAmount + watch('taxAmount') - discount);
      if (packagesCnt.some(cnt => Number.isNaN(cnt))) {
        setValue(
          'packages',
          packagesCnt.map(cnt => (Number.isNaN(cnt) ? 0 : cnt))
        );
      }
    }
  }, [packageCntString]);

  const batchSlot = selectedVenue?.slot[0][0];
  const commonProps = { topLabel: true, register, errors };
  return (
    <form onSubmit={handleSubmit(onSaveDetails)} className="w-full pl-6 pr-64 pt-4">
      <h1 className="text-2xl font-bold mb-6">Book tickets</h1>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <Input
          label="Customer Name"
          type="text"
          placeholder="Enter Customer Name"
          name="customerName"
          {...commonProps}
        />
        <Input
          label="Booking Amount"
          type="number"
          prefix="₹"
          name="bookingAmount"
          readonly
          {...commonProps}
        />
        <Input
          label="Customer Phone Number"
          type="text"
          prefix="+91"
          placeholder="Enter Customer Phone Numbe"
          name="phoneNumber"
          {...commonProps}
        />
        <Input label="Discount" type="number" prefix="₹" name="discount" readonly {...commonProps} />
        <InputSelect
          label="Source"
          placeholder="Select Discovery source from list"
          name="source"
          options={[
            { id: 'Source 1', value: 'Source 1' },
            { id: 'Source 2', value: 'Source 2' }
          ]}
          required
        />
        <Input
          label="Tax Amount"
          type="number"
          prefix="₹"
          defaultValue={99}
          name="taxAmount"
          readonly
          {...commonProps}
        />
        <InputSelect
          label="Product"
          placeholder="Select Product from list"
          name="listingId"
          options={products.map(product => ({ id: product.id, value: product.name }))}
          required
          {...commonProps}
        />
        <Input
          label="Total Amount to be Paid"
          type="number"
          prefix="₹"
          readonly
          name="totalAmount"
          {...commonProps}
        />
      </div>

      <div className="w-1/2 space-y-4 mb-6">
        <InputSelect
          label="Venue"
          placeholder="Select Venue from list"
          name="venueId"
          options={
            products
              .find(product => product.id === selectedListingId)
              ?.venues?.map(venue => ({
                id: venue.id,
                value: venue.address
              })) ?? []
          }
          required
          {...commonProps}
        />
        {/* TODO: Update this for multiselect date */}
        <InputSelect
          label="Date & Time"
          placeholder="Select Date from list"
          name="date"
          options={[{ id: getPrettyDateString(batchSlot), value: getPrettyDateString(batchSlot) }]}
          required
          {...commonProps}
        />
        {selectedVenue && (
          <div className="mb-6">
            <p className="block mb-1 font-medium">Packages</p>
            {selectedVenue.packages.map((pkg, ind) => (
              <div
                key={ind}
                className="bg-white p-4 border rounded-md mb-4 flex justify-between items-center"
              >
                <div>
                  <label htmlFor={`packages.${ind}`} className="font-bold mb-2">
                    {pkg.title}
                  </label>
                  <p className="text-sm text-black mb-2 whitespace-pre-line">{pkg.description}</p>
                  <p className="font-bold mt-2">₹ {pkg.price}</p>
                </div>
                <Input
                  name={`packages.${ind}`}
                  type="number"
                  className="w-10 h-10 text-center flex border rounded-md bg-[#35e3a4] text-black"
                  {...commonProps}
                />
              </div>
            ))}
          </div>
        )}

        {/* Payment Mode Section */}
        <InputSelect
          label="Payment Mode"
          placeholder="Select Discovery source from list"
          name="paymentMode"
          options={[
            { id: 'Mode 1', value: 'Mode 1' },
            { id: 'Mode 2', value: 'Mode 2' }
          ]}
          required
        />
      </div>

      {/*Save Bill Button*/}
      <div className="w-1/2 mb-8">
        <Button
          className="bg-[#35e3a4] w-1/2 flex justify-center items-center h-[40px] font-medium rounded-md"
          label="Save Bill"
          isLoading={isLoading[getProductsThunk.pending.toString()]}
        />
      </div>
    </form>
  );
};

export default OfflineTickets;
