import * as yup from 'yup';

export const contactUsSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required').email('Email should be valid'),
  phoneNumber: yup
    .string()
    .required('Phone Number is required')
    .matches(/^[0-9]{10}$/, 'Phone number must be a 10-digit number without any special characters'),
  message: yup.string().required('Message is required')
});
export type ContactUsFormData = yup.InferType<typeof contactUsSchema>;
