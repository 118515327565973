import classNames from 'classnames';
import _ from 'lodash';
import { KeyboardEventHandler } from 'react';
import { FieldErrors, FieldPath, FieldValues, PathValue, UseFormRegister } from 'react-hook-form';

export interface InputFormFieldProps<TFieldValues extends FieldValues> {
  register?: UseFormRegister<TFieldValues>;
  errors?: FieldErrors<TFieldValues>;
  name: FieldPath<TFieldValues>;
}

interface InputProps<TFieldValues extends FieldValues> extends InputFormFieldProps<TFieldValues> {
  label?: string;
  type: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: PathValue<TFieldValues, FieldPath<TFieldValues>>;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  topLabel?: boolean;
  prefix?: string;
  suffix?: string;
  hidden?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

export type CommonInputProps<TFieldValues extends FieldValues> = Partial<InputProps<TFieldValues>>;

const Input = <TFieldValues extends FieldValues>({
  label,
  type,
  name,
  register,
  errors,
  placeholder,
  defaultValue,
  disabled = false,
  readonly = false,
  required = false,
  className,
  topLabel = false,
  prefix,
  suffix,
  hidden = false,
  ...props
}: InputProps<TFieldValues>): JSX.Element => {
  const error = _.get(errors, name)?.message as string;

  const inputWrapperClasses = classNames('flex w-full', { 'flex-col': topLabel, 'items-center': !topLabel });

  const labelClasses = classNames('font-medium', { 'block mb-1': topLabel, 'flex-shrink-0 w-36': !topLabel });

  const customBgColor = /(bg-\S+)/.exec(className ?? '')?.[1] ?? '';
  const bgColor = {
    'bg-gray-300': disabled && !customBgColor,
    'bg-[#f6f6f6]': topLabel && !disabled && !customBgColor,
    [customBgColor]: customBgColor
  }; // Added dynamic background

  const inputClasses = classNames('flex-1 min-w-0 py-2 border-none outline-none', bgColor);
  const containerClasses = classNames(
    'w-full flex pl-3 items-center border rounded overflow-hidden',
    bgColor,
    className
  );

  const errorClasses = classNames('text-red-600 text-sm font-semibold mt-1', { hidden: !error || hidden });

  return (
    <div className={classNames('mb-2', { hidden })}>
      <div className={inputWrapperClasses}>
        {label && (
          <label htmlFor={String(name)} className={labelClasses}>
            {label} {required && <span className="text-red-600">*</span>}
          </label>
        )}

        <div className={containerClasses}>
          {prefix && <span className="pr-2 flex-shrink-0 truncate">{prefix}</span>}
          <input
            id={String(name)}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            defaultValue={defaultValue}
            {...register?.(name, {
              required,
              disabled,
              valueAsNumber: type === 'number'
            })}
            {...props}
            className={inputClasses} // ClassNames moved to a single location
          />
          {suffix && <span className="px-2 flex-shrink-0 truncate">{suffix}</span>}
        </div>
      </div>
      {error && <p className={errorClasses}>{error}</p>}
    </div>
  );
};

export default Input;
