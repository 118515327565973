export interface PresignedPost {
  url: string;
  fields: Record<string, string>;
}

export enum PresignedPostUploadType {
  PROP_DOCUMENT_GST = 'PROP_DOCUMENTS_GST',
  PROP_LOGO = 'PROP_LOGO',
  PROP_LANDING_IMAGES = 'PROP_LANDING_IMAGES',
  PROP_CATEGORY_IMAGES = 'PROP_CATEGORY_IMAGES',
  PROP_LISTING_IMAGES = 'PROP_LISTING_IMAGES'
}
