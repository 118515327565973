import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { BusinessDetail, WebsiteDetail, BankDetail } from '../../utils/entity/prop';
import { PropBookingMeta } from '../../utils/models/booking';
import { addLoadingReducers, BaseState, ThunkReducerMap } from '../common';
import {
  authProp,
  createProp,
  getBankDetail,
  getBooking,
  getBookings,
  getBusinessDetail,
  getPresignedPost,
  getWebsiteDetail,
  loginProp,
  logoutProp,
  propExist,
  saveBankDetail,
  saveBusinessDetail,
  saveWebsiteDetail,
  submitQuery
} from '../thunk/propThunk';

const propThunks = {
  propExistThunk: createAsyncThunk('prop/exist', propExist),
  loginPropThunk: createAsyncThunk('prop/loginProp', loginProp),
  createPropThunk: createAsyncThunk('prop/createProp', createProp),
  authPropThunk: createAsyncThunk('prop/authProp', authProp),
  logoutPropThunk: createAsyncThunk('prop/logoutProp', logoutProp),
  submitQueryThunk: createAsyncThunk('prop/submitQuery', submitQuery),
  getPresignedPostThunk: createAsyncThunk('prop/getPresignedPost', getPresignedPost),
  saveBusinessDetailThunk: createAsyncThunk('prop/saveBusinessDetail', saveBusinessDetail),
  getBusinessDetailThunk: createAsyncThunk('prop/getBusinessDetail', getBusinessDetail),
  saveBankDetailThunk: createAsyncThunk('prop/saveBankDetail', saveBankDetail),
  getBankDetailThunk: createAsyncThunk('prop/getBankDetail', getBankDetail),
  saveWebsiteDetailThunk: createAsyncThunk('prop/saveWebsiteDetail', saveWebsiteDetail),
  getWebsiteDetailThunk: createAsyncThunk('prop/getWebsiteDetail', getWebsiteDetail),
  getBookingsThunk: createAsyncThunk('prop/getBookings', getBookings),
  getBookingThunk: createAsyncThunk('prop/getBooking', getBooking)
};
export const {
  propExistThunk,
  loginPropThunk,
  createPropThunk,
  authPropThunk,
  logoutPropThunk,
  submitQueryThunk,
  getPresignedPostThunk,
  saveBusinessDetailThunk,
  getBusinessDetailThunk,
  saveWebsiteDetailThunk,
  getWebsiteDetailThunk,
  saveBankDetailThunk,
  getBankDetailThunk,
  getBookingsThunk,
  getBookingThunk
} = propThunks;

interface PropState extends BaseState {
  propId?: string;
  businessDetail?: BusinessDetail;
  websiteDetail?: WebsiteDetail;
  bankDetail?: BankDetail;
  bookingMetas?: PropBookingMeta[];
}

const initialState: PropState = {
  isLoading: { [authPropThunk.pending.toString()]: true }, //Defailting this value so that the loading symbol apears when a protectedLayout is opened
  error: {},
  propId: undefined
};
export const propSlice = createSlice({
  name: 'prop',
  initialState,
  reducers: {
    setGeneratedDomain: (state, action) => {
      state.businessDetail = {
        ...((state.businessDetail ?? {}) as BusinessDetail),
        generatedDomain: action.payload as string
      };
    }
  },
  extraReducers: builder => {
    addLoadingReducers(
      builder,
      propThunks,
      new ThunkReducerMap<PropState, typeof propThunks>()
        .addCase(loginPropThunk.fulfilled, (state, action) => {
          state.propId = action.payload?.id;
        })
        .addCase(createPropThunk.fulfilled, (state, action) => {
          state.propId = action.payload?.id;
        })
        .addCase(authPropThunk.fulfilled, (state, action) => {
          state.propId = action.payload?.id;
        })
        .addCase(logoutPropThunk.fulfilled, state => {
          _.assign(state, _.cloneDeep(initialState));
          delete state.isLoading[authPropThunk.pending.toString()];
        })
        .addCase(getBusinessDetailThunk.fulfilled, (state, action) => {
          state.businessDetail = action.payload;
        })
        .addCase(getWebsiteDetailThunk.fulfilled, (state, action) => {
          state.websiteDetail = _.isEmpty(action.payload) ? undefined : action.payload;
        })
        .addCase(getBankDetailThunk.fulfilled, (state, action) => {
          state.bankDetail = action.payload;
        })
        .addCase(getBookingsThunk.pending, state => {
          state.bookingMetas = undefined;
        })
        .addCase(getBookingsThunk.fulfilled, (state, action) => {
          state.bookingMetas = action.payload.bookings;
        })
    );
  }
});

export const { setGeneratedDomain } = propSlice.actions;
