import { GetThunkAPI } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import axiosInstance from '../../utils/axios';
import { BankDetail, BusinessDetail, WebsiteDetail } from '../../utils/entity/prop';
import { PropBookingMeta, GetPropBookingByIdResponse } from '../../utils/models/booking';
import { PresignedPost, PresignedPostUploadType } from '../../utils/models/common';
import { toError } from '../../utils/utils';
import { ContactUsFormData } from '../../validation/contactUs';
import { BankDetailsFormData } from '../../validation/general/bankDetail';
import { BusinessDetailFormData } from '../../validation/general/businessDetail';
import { WebsiteDetailFormData } from '../../validation/general/websiteDetails';
import { AsyncThunkConfig, FileTypeToString, withApiErrorHandler } from '../common';

const withUnautherizedErrorHandler = async <T>(operation: () => Promise<T>): Promise<T> => {
  try {
    return await operation();
  } catch (error) {
    if (error instanceof AxiosError && error?.response?.data?.message) {
      throw Error(error.response.data.message, { cause: error });
    } else {
      throw toError(error);
    }
  }
};

export const submitQuery = async (data: ContactUsFormData): Promise<{ exist: boolean }> => {
  return withUnautherizedErrorHandler(async () => {
    const response = await axiosInstance.post(`/prop/query`, data);
    return response.data;
  });
};

export const propExist = async ({ email }: { email: string }): Promise<{ exist: boolean }> => {
  return withUnautherizedErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/exist`, { params: { email } });
    return response.data;
  });
};

export const loginProp = async ({ email, password }: { email: string; password: string }) => {
  return withUnautherizedErrorHandler(async () => {
    const response = await axiosInstance.post(`/prop/login`, { email, password });
    return response.data;
  });
};

export const createProp = async ({ email, password }: { email: string; password: string }) => {
  return withUnautherizedErrorHandler(async () => {
    const response = await axiosInstance.post(`/prop`, { email, password });
    return response.data;
  });
};

export const authProp = async () => {
  return withUnautherizedErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/auth`);
    return response.data;
  });
};

export const logoutProp = async (_: void, { getState }: GetThunkAPI<AsyncThunkConfig>) => {
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.post(`/prop/logout`);
    return response.data;
  }, getState().prop.propId);
};

export const getPresignedPost = async (
  { uploadType, noOfFiles }: { uploadType: PresignedPostUploadType; noOfFiles?: number },
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<{ presignedPosts: Array<PresignedPost> }> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/presigned-post`, {
      params: { uploadType, noOfFiles }
    });
    return response.data;
  }, propId);
};

export const uploadFile = async (
  presignedPost: PresignedPost,
  file: File
): Promise<{ etag: string; location: string }> => {
  if (!(file instanceof File)) {
    throw new Error("The type of object to upload should be 'File'");
  }
  const formData = new FormData();
  Object.entries(presignedPost.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('Content-Type', file.type);
  formData.append('file', file);

  const res = await axios.post(presignedPost.url, formData, { withCredentials: false });
  return { etag: res.headers.etag, location: res.headers.location };
};

export const saveBusinessDetail = async (
  businessDetail: FileTypeToString<BusinessDetailFormData>,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<void> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.put(`/prop/${propId}/business-detail`, businessDetail);
    return response.data;
  }, propId);
};

export const getBusinessDetail = async (
  _: void,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<BusinessDetail> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/business-detail`);
    return response.data;
  }, propId);
};

export const saveWebsiteDetail = async (
  websiteDetail: FileTypeToString<WebsiteDetailFormData>,
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.put(`/prop/${propId}/website-detail`, websiteDetail);
    return response.data;
  }, propId);
};

export const getWebsiteDetail = async (
  _: void,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<WebsiteDetail> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/website-detail`);
    return response.data;
  }, propId);
};

export const saveBankDetail = async (
  bankDetail: BankDetailsFormData,
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.put(`/prop/${propId}/bank-detail`, bankDetail);
    return response.data;
  }, propId);
};

export const getBankDetail = async (
  _: void,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<BankDetail> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/bank-detail`);
    return response.data;
  }, propId);
};

export const getBookings = async (
  key: { phoneNumber: string } | { bookingId: string } | { userId: string } | undefined,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<{ bookings: PropBookingMeta[] }> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/bookings`, { params: key });
    return response.data;
  }, propId);
};

export const getBooking = async (
  bookingId: string,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<GetPropBookingByIdResponse> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/bookings/${bookingId}`);
    return response.data;
  }, propId);
};
