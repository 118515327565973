import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, Link } from 'react-router-dom';

import Button from '../../components/button/Button';
import Modal from '../../components/common/Modal';
import Input from '../../components/input/Input';
import InputFile, { uploadLocalFiles } from '../../components/input/InputFile';
import { InputMultiSelect } from '../../components/input/InputMultiSelect';
import { Option } from '../../components/input/InputSelect';
import InputTextArea from '../../components/input/InputTextArea';
import Loading from '../../components/loading/Loading';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { errorMessage } from '../../hooks/useNotifications';
import {
  addListingThunk,
  getListingThunk,
  getVenuesMetaThunk,
  updateListingThunk
} from '../../redux/reducer/listingSlice';
import { getWebsiteDetailThunk } from '../../redux/reducer/propSlice';
import { PresignedPostUploadType } from '../../utils/models/common';
import { toError, withErrorHandler } from '../../utils/utils';
import {
  AddProductFormData,
  addProductSchema
} from '../../validation/operations/addOrEditProduct/addProductSchema';

const AddProducts: React.FC = () => {
  const navigate = useNavigate();
  const [venues, setVenues] = useState<
    { id: string; from: Date; to: Date; address: string; phoneNumber: string }[]
  >([]);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleDelete = (index: number) => {
    setDeleteIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleConfirmDelete = () => {
    setVenues(venues.filter((_, i) => i !== deleteIndex));
    handleCloseModal();
  };

  const { listingId } = useParams();
  const listing = useAppSelector(state => state.listing.listingMap[listingId ?? '']);
  const isLoading = useAppSelector(state => state.listing.isLoading);
  const websiteDetail = useAppSelector(state => state.prop.websiteDetail);
  const error = useAppSelector(state => state.listing.error);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!websiteDetail) {
      dispatch(getWebsiteDetailThunk());
    }
  }, []);

  let values: AddProductFormData | undefined;
  useEffect(() => {
    if (listingId) {
      dispatch(getListingThunk(listingId));
      (async () => {
        try {
          const venues = await dispatch(getVenuesMetaThunk(listingId)).unwrap();
          setVenues(
            venues.map(venue => ({
              id: venue.id!,
              from: new Date(venue.slots[0][0][0].from),
              to: new Date(venue.slots[0][0][0].to),
              address: venue.address,
              phoneNumber: venue.phoneNumber
            }))
          );
        } catch (err: unknown) {
          errorMessage(toError(err).message ?? 'Unable to get venues for listing');
        }
      })();
    }
  }, [listingId]);

  if (listing) {
    values = {
      title: listing.title,
      highlight: listing.highlight,
      overview: listing.overview,
      ageGroup: listing.ageGroup,
      tags: listing.tags,
      images: listing.images.map(image => ({ loc: image.loc }))
    };
  }
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(addProductSchema),
    values
  });

  if (listingId) {
    if (isLoading[getListingThunk.pending.toString()]) {
      return <Loading />;
    } else if (error[getListingThunk.pending.toString()]) {
      navigate('/not-found');
      return <div />;
    }
  }

  const categoryOptions: Option[] =
    websiteDetail?.categories.map(category => ({
      id: category.title,
      value: category.title
    })) ?? [];
  const commonProps = {
    register,
    errors,
    topLabel: true
  };

  const onSave = async (data: AddProductFormData) => {
    withErrorHandler(async () => {
      const listingImages = await uploadLocalFiles(
        PresignedPostUploadType.PROP_LISTING_IMAGES,
        ...data.images
      );
      setValue(
        'images',
        listingImages.map(image => ({ loc: image }))
      );
      if (listingId) {
        await dispatch(
          updateListingThunk({ listingId, listingData: { ...data, images: listingImages } })
        ).unwrap();
      } else {
        const { id } = await dispatch(addListingThunk({ ...data, images: listingImages })).unwrap();
        navigate(`/product/${id}`);
      }
    }, 'Successfully saved Product Details');
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <div>
        <div className="mb-6">
          <h1 className="text-2xl font-bold ml-4">Product Details</h1>
        </div>
        <div className="flex space-y-6">
          <main className="w-4/5 p-3">
            <div className="grid grid-cols-2 gap-8 mb-8">
              <div className="space-y-4">
                <Input
                  required
                  name="title"
                  label="Title"
                  type="text"
                  placeholder="Enter your product title"
                  {...commonProps}
                />
                <InputMultiSelect
                  name="tags"
                  label="Category Tags "
                  maxTags={4}
                  required
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  options={categoryOptions}
                />
              </div>
              <InputFile
                name="images"
                label="Images"
                required
                multiple
                maxFiles={4}
                watch={watch}
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                addtionalText={<p className="text-sm font-medium mt-1 text-gray-300">(Maximum 4 Images)</p>}
                accept="image/*"
              />
            </div>
            <div className="grid grid-cols-2 gap-8 mb-8">
              <InputTextArea
                label="Overview"
                name="overview"
                maxLength={1000}
                placeholder="Enter Description"
                required
                {...commonProps}
              />
              <InputTextArea
                label="Highlight"
                name="highlight"
                maxLength={500}
                placeholder="Enter Description"
                required
                {...commonProps}
              />
            </div>
            <div className="grid grid-cols-2 gap-8 mb-8">
              <div className="grid grid-cols-2 gap-4">
                <Input
                  name="ageGroup.from"
                  label="Age From"
                  type="number"
                  placeholder="Enter from age"
                  required
                  {...commonProps}
                />
                <Input
                  name="ageGroup.to"
                  label="Age To"
                  placeholder="Enter to age"
                  type="number"
                  required
                  {...commonProps}
                />
              </div>
              {/* <InputSelect
                {...commonProps}
                label="Booking Type"
                placeholder="Select Booking Type from list"
                name="bookingType"
                options={[
                  { id: 'Type1', value: 'Type 1' },
                  { id: 'Type2', value: 'Type 2' }
                ]}
                required
              /> */}
            </div>
            <div className="mb-8">
              <h2 className="block text-gray-700 mb-2 font-bold">Venue</h2>

              <div className="bg-white pt-2 rounded grid grid-cols-3 gap-4">
                <button
                  className="bg-[#f6f6f6] p-4 text-center rounded cursor-pointer flex flex-col items-center justify-center"
                  onClick={() => {
                    if (listingId) {
                      navigate('venue');
                    } else {
                      errorMessage('Please Save the Product details first');
                    }
                  }}
                  type="button"
                >
                  <i className="fas fa-plus text-[#5a5a5a]"></i>
                  <p className="font-medium text-[#9b9b9b]">Click here to add Venue</p>
                </button>

                {venues.map((venue, index) => (
                  <div key={index} className="bg-[#f6f6f6] p-4 rounded relative">
                    <p className="text-lg font-semibold">{venue.from.toLocaleDateString()}</p>
                    <p className="text-sm font-medium mt-1">
                      {venue.from.toLocaleTimeString()} - {venue.to.toLocaleTimeString()}{' '}
                    </p>
                    <p className="text-base font-semibold mt-1">{venue.address}</p>
                    <p className="text-sm font-semibold mt-1">{venue.phoneNumber}</p>
                    <Link to={`venue/${venue.id}`} className="text-black text-[13px] underline mt-3">
                      Click here to edit
                    </Link>
                    {/* // TODO: Check whether we neeed this cross button or not... If Yes update the logic to call the delete venue api */}
                    <button
                      onClick={() => handleDelete(index)}
                      type="button"
                      className="absolute top-0 right-0 text-black mt-1 mr-1 rounded-full w-4 h-4 flex items-center justify-center"
                    >
                      <i className="fas fa-times text-xs"></i>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </main>
          <div className="flex flex-col items-end space-y-4 ml-2 font-medium">
            <Button
              className="bg-emerald-400 text-white px-2 py-2 rounded w-56"
              type="submit"
              label="Save Changes"
              isLoading={isLoading[updateListingThunk.pending.toString()]}
            />
            {/* <button className="bg-[#f8f8f8] text-[#ff0000] px-2 py-2 rounded w-56" type="button">
              Stop bookings
            </button> */}
          </div>
        </div>
        <Modal isOpen={isModalOpen} width="w-1/3">
          <p className="mb-4 break-words text-center text-lg">Are you sure you want to delete this item?</p>
          <div className="flex justify-center gap-4">
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
              onClick={handleConfirmDelete}
              type="button"
            >
              Confirm
            </button>
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-400"
              onClick={handleCloseModal}
              type="button"
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </form>
  );
};

export default AddProducts;
