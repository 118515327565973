import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';

import { PackageCard } from './PackageCard';
import Button from '../../../components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { updateVenuePackagesThunk } from '../../../redux/reducer/listingSlice';
import { ProductVenue } from '../../../utils/models/listing';
import { getPrettyDateString, withErrorHandler } from '../../../utils/utils';
import { packageDetailSchema, VenueCardFormData } from '../../../validation/operations/products/venueCard';

interface VenueCardProps {
  venue: ProductVenue;
  productId: string;
}

export const VenueCard: React.FC<VenueCardProps> = ({ venue, productId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector(state => state.listing);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<VenueCardFormData>({
    resolver: yupResolver(packageDetailSchema),
    values: {
      packages: venue.packages.map(pkg => ({
        id: pkg.id!,
        price: pkg.price,
        totalTickets: pkg.totalTickets,
        availableTickets: pkg.totalTickets - pkg.bookedTickets,
        discount: pkg.discount
      }))
    }
  });

  const onSaveDetails = async (data: VenueCardFormData) => {
    console.log(data);
    withErrorHandler(async () => {
      return await dispatch(
        updateVenuePackagesThunk({
          listingId: productId,
          venueId: venue.id,
          packages: data.packages.map(pkg => _.pick(pkg, ['id', 'price', 'discount', 'totalTickets']))
        })
      ).unwrap();
    }, 'Succesfuly saved package details');
  };

  return (
    <div className="bg-zinc-50 p-6 rounded shadow-md mt-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">{venue.address}</h3>
        <div className="flex items-center">
          <i className="fas fa-calendar-alt mr-2"></i>
          <span>{getPrettyDateString(venue.slot[0][0])}</span>
          <i className="fas fa-map-marker-alt ml-4 mr-2"></i>
          <span>{venue.city}</span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSaveDetails)}>
        <div className="grid grid-cols-3 gap-6 mb-4">
          {venue.packages.map((pkg, index) => (
            <PackageCard
              key={pkg.id}
              index={index}
              useForm={{ register, errors, watch, setValue }}
              title={pkg.title}
              bookedTickets={pkg.bookedTickets}
            />
          ))}
        </div>
        <div className="flex justify-between items-center">
          <div className="gap-2 flex flex-row">
            <Button
              label="Save Changes"
              type="submit"
              className="bg-[#35E0A1] text-black px-4 py-2 rounded"
              isLoading={isLoading[updateVenuePackagesThunk.pending.toString()]}
            />
            <Button
              label="View Bookings"
              onClick={() => {
                navigate(`/bookings`);
              }}
              className="bg-[#35E0A1] text-black px-4 py-2 rounded"
            />
            <Link
              to={`/product/${productId}/venue/${venue.id}`}
              className="bg-[#35E0A1] text-black px-4 py-2 rounded"
            >
              View / Edit Venue Details
            </Link>
          </div>
          {/* <div className="flex items-center">
            <span>Pause Bookings</span>
            <label className="toggle-switch ml-2">
              <input type="checkbox" />
              <span className="slider"></span>
            </label>
          </div> */}
        </div>
      </form>
    </div>
  );
};
