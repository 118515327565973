import * as yup from 'yup';

import { getFileSchema } from '../common';

export const getBusinessDetailSchema = (domainRequired: boolean) => {
  const schema = {
    businessName: yup.string().required('Business Name is required'),
    email: yup.string().email().required('Business Email is required'),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .matches(/^[0-9]{10}$/, 'Phone number must be a 10-digit number without any special characters'),
    alternatePhoneNumber: yup
      .string()
      .required('Alternate Phone number is required')
      .matches(/^[0-9]{10}$/, 'Phone number must be a 10-digit number without any special characters')
      .test(
        'not-same-as-phoneNumber',
        'Alternate Phone Number should not be the same as Phone Number',
        function (value) {
          return value !== this.parent.phoneNumber;
        }
      ),
    city: yup.string().required('City is required'),
    address: yup.string().required('Business address is required'),
    generatedDomain: yup
      .string()
      .min(1)
      .max(63)
      .matches(/^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/, 'Invalid domain format'),
    gst: getFileSchema(['application/pdf'], 2 * 1024 * 1024).required('GST File is required')
  };
  if (domainRequired) {
    schema.generatedDomain.required('Domain is required');
  }
  return yup.object(schema);
};

export type BusinessDetailFormData = yup.InferType<ReturnType<typeof getBusinessDetailSchema>>;
